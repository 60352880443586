import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo.js";
import Layout from "../../components/Layout.js";
import MainContent from "../../components/MainContent.js";
import Breadcrumb from "../../components/Breadcrumb.js";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper.js";
import H from "../../components/Headline.js";
import { useAlternateLangs } from "../../components/Hreflangs.js";
import Banner from "../../components/Banner.js";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin.js";
import { experienceYears } from "../../components/Helpers.js";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Hjem: "/da",
  "Om mig": "/da/om-mig"
};

// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);
const About = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="Om Matthias Kupperschmidt" lang="da" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hej, jeg er Matthias</H>
              <p>Jeg optimerer hjemmesider til Googles søgeresultater og implementerer analysetilpasninger.</p>
              <p>
                Jeg er tysker og bor i København, Danmark. Jeg har arbejdet i {experienceYears} år hos flere digitale bureauer,
                før jeg begyndte at arbejde som <Link to="/da/google-analytics-freelancer">freelancer for Google Analytics</Link> og SEO.
              </p>
              <p>
                Ifølge flere personlighedstests er jeg meget analytisk, rationel og struktureret, samtidig med at jeg er
                målrettet og pragmatisk.
              </p>
              <H as="h2">Min historie</H>
              <p>
                Som 14-årig byggede jeg min første hjemmeside for at bytte film på skolegården. Det var den bedste måde at vise min
                filmsamling til mine klassekammerater. Jeg husker, at jeg lavede de første HTML'er med Microsoft Word og senere
                med en editor kaldet "Phase5".
              </p>
              <p>
                Senere byggede jeg min egen e-handels webshop for bordfodbold, kaldet "Kickerkult". Jeg var 18 og en aspirerende, wannabe-professionel
                bordfodboldspiller, så det var en god kombination af to interesser, jeg havde.
                <br /> I løbet af den tid spillede jeg mange turneringer i Berlin og over hele Tyskland. Samtidig styrede jeg
                min webshop, lærte at sende email nyhedsbreve og begyndte at arbejde med SEO.
                <br />
                Til sidst blev jeg lidt afhængig af internettet og dets muligheder og ønskede at fokusere min karriere på
                det.
              </p>
              <p>
                I dag hjælper jeg virksomheder i forskellige størrelser med at komme til toppen af Googles søgeresultater og
                at spore deres brugeradfærd - uanset hvor i verden virksomheden er fra.
              </p>
              <p>
                Og derudover skriver jeg om SEO og Analytics emner på min <Link to="/da/blog">blog</Link>.
              </p>
              <H as="h2">
                Hvilken by er den næste Silicon Valley?
                <br />
                Din by!
              </H>
              <p>
                Jeg er en stærk tilhænger af, at man ikke behøver at flytte til de store, prangende byer for at opnå en stor karriere.
                Gennem internettet finder moderne virksomheder, professionelle og ligesindede hinanden og arbejder sammen - uanset geografisk afstand.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Lad os holde kontakten!</div>
          <div className="serif">
            Bare rolig, jeg vil knap nok sende dig emails. Tilmeld dig her for at være sikker på at høre, når jeg har noget vigtigt at dele.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          {/* <div className="banner__header serif headline">
          “Jeg har brug for hjælp med SEO eller analytics”
        </div>
        <div className="serif">
          Bare rolig, jeg vil knap nok sende dig emails. Tilmeld dig her for at
          være sikker på at høre, når jeg har noget vigtigt at dele.
        </div> */}
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>
  </Layout>
);

export default React.memo(About);
